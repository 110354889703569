<script setup lang="ts">
import type { Media } from '~/types'

const url = ref('')

setTimeout(() => {
  url.value = 'https://europe.olemovienews.com/ts2/20230925/hDylrvkD/mp4/hDylrvkD.mp4/index-v1-a1.m3u8'
}, 2000)

const mockList: Media[] = [
  {
    id: 'cscs12c212',
    poster_path: '002.png',
    title: '倚天屠龙记',
    name: 'cscs',
    watch_count: '20,030',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  },
  {
    id: 'cscs1121c212',
    poster_path: '003.png',
    title: '倚天屠龙记',
    name: 'cscs',
    watch_count: '20,030',
    update_info: '已更新至300集',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  },
  {
    id: 'cscs1cscs2c212',
    poster_path: '004.png',
    title: '倚天屠龙记',
    name: 'cscs',
    update_info: '已更新至300集',
    watch_count: '20,030',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  },
  {
    id: 'cscs1ss2c212',
    poster_path: '005.png',
    title: '倚天屠龙记',
    name: 'cscs',
    update_info: '已更新至300集',
    watch_count: '20,030',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  },
  {
    id: 'cscs12cccf212',
    poster_path: '001.webp',
    title: '倚天屠龙记',
    name: 'cscs',
    watch_count: '20,030',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  },
  {
    id: 'cscs12c21qq2',
    poster_path: '001.webp',
    title: '倚天屠龙记',
    name: 'cscs',
    watch_count: '20,030',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  },
  {
    id: 'cscs12c2ffas12',
    poster_path: '001.webp',
    title: '倚天屠龙记',
    name: 'cscs',
    watch_count: '20,030',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  },
  {
    id: 'cscs12cgg212',
    poster_path: '001.webp',
    title: '倚天屠龙记',
    name: 'cscs',
    watch_count: '20,030',
    update_info: '已更新至300集',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  },
  {
    id: 'cscs12chhh212',
    poster_path: '001.webp',
    title: '倚天屠龙记',
    name: 'cscs',
    update_info: '已更新至300集',
    watch_count: '20,030',
    starring: '王宝强、刘昊然主演',
    video: false,
    media_type: 'movie'
  }
]

const urls = [
  {
    id: 1,
    index: 1,
    url: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4'
  },
  {
    id: 2,
    index: 2,
    url: 'https://europe.olemovienews.com/ts2/20230925/hDylrvkD/mp4/hDylrvkD.mp4/index-v1-a1.m3u8'
  }
]

function swicthWideo(item: (typeof urls)[0]) {
  url.value = item.url
}
</script>

<template>
  <main ma flex-1 p-2>
    <section ma mb-10 lg:flex>
      <VideoPlayer :url="url" lg:mr-4 lg:flex-1 />
      <div lg:flex-1>
        <div mb-10>
          <h3>简介：调用简介组件</h3>
          <p v-for="v in 6" :key="v">
            {{ v }}
          </p>
        </div>
        <div>
          <p>调用剧集列表组件</p>
          <button v-for="item in urls" :key="item.id" type="button" mr-2 @click="swicthWideo(item)">
            第{{ item.index }}集
          </button>
        </div>
      </div>
    </section>

    <section>
      <MediaGrid>
        <MediaCard v-for="item of mockList" :key="item.id" type="movie" :item="item" />
      </MediaGrid>
    </section>
  </main>
</template>

<style lang="less" scoped></style>
